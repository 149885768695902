$("#slider").addClass 'ready'
$("#slider").click ->
	slider = @
	if $(slider).hasClass 'ready'
		$(slider).toggleClass 'ready not-ready'
		slides = $(".slide", slider)
		active = $(".slide.active", slider)
		active.addClass 'leaving-active'
		if slides.filter(':last').hasClass 'active'
			slides.filter(':first').addClass 'active'
		else
			active.next().addClass 'active'
		setTimeout ->
			active.removeClass 'active leaving-active'
			$(slider).toggleClass 'not-ready ready'
		, 800